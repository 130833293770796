import type { Api } from '@rialtic/api'
import type { PaAPI } from '@rialtic/types'
import { acceptHMRUpdate, defineStore } from 'pinia'

interface MuxResultConnector {
  id: string
  name: string
  result: string
}
interface MuxResult {
  id: string
  url?: string
  result: string
  dns?: string
  connectors?: MuxResultConnector[]
}

interface AdminUsersData {
  filters: PaAPI.Get['/connector/{id}/policy/filters']
  users: PaAPI.Get['/user']
}

type WorkspaceById = (
  workspaceId: string,
) => Partial<PaAPI.Get['/workspace/{id}']>

export const useAdminWorkspaceManger = defineStore('admin-workspace-manager', {
  state: () => {
    return {
      currentConnectors: [] as PaAPI.Get['/connector'],
      platformHealth: null as Api.PlatformHealth | null,
      users: [] as PaAPI.Get['/user'],
      usersFilters: {} as PaAPI.Get['/connector/{id}/policy/filters'], // Using policy filters as alias for users filters
      usersSyncing: false,
      workspaces: {} as Record<string, PaAPI.Get['/workspace/{id}']>,
    }
  },

  getters: {
    connectors: (state) =>
      state.currentConnectors.sort((a, b) => a.name.localeCompare(b.name)),
    muxHealth: (state): MuxResult[] =>
      state.platformHealth?.api?.healthChecks?.mux?.results || [],
    workspaceById:
      (state): WorkspaceById =>
      (workspaceId: string) =>
        state.workspaces[workspaceId] || {},
  },

  actions: {
    async create({ id, ...workspace }: Partial<PaAPI.PostBody['/workspace']>) {
      const workersApi = useWorkersApi()

      const workspaceToCreate = {
        ...workspace,
        ...(id?.trim().length ? { id } : {}),
      }

      const data: PaAPI.Post['/workspace'] = await workersApi.fetch(
        '/admin/workspace',
        {
          baseURL: workersApi.baseURL,
          method: 'POST',
          body: workspaceToCreate,
        },
      )

      this.workspaces = {
        [data.id]: data,
      }

      return this.workspaces
    },

    async delete(workspaceId: string) {
      const workersApi = useWorkersApi()

      await workersApi.fetch(`/admin/workspace/${workspaceId}`, {
        baseURL: workersApi.baseURL,
        method: 'DELETE',
      })

      const currentWorkspaces = {
        ...this.workspaces,
      }
      delete currentWorkspaces[workspaceId]

      this.workspaces = currentWorkspaces

      return this.workspaces
    },

    async getAll() {
      const workersApi = useWorkersApi()

      const data: Record<string, PaAPI.Get['/workspace/{id}']> =
        await workersApi.fetch('/admin/workspace', {
          baseURL: workersApi.baseURL,
        })
      this.workspaces = {
        ...data,
      }

      return this.workspaces
    },

    async getPlatformHealth() {
      const workersApi = useWorkersApi()

      try {
        const data: Api.PlatformHealth = await workersApi.fetch('/admin/health')

        this.platformHealth = data

        return data
      } catch (error) {
        const { $datadog } = useNuxtApp()
        $datadog.addError(error)
      }
    },

    async getUsers() {
      const workersApi = useWorkersApi()

      const { filters, users }: AdminUsersData = await workersApi.fetch(
        '/admin/user',
        {
          baseURL: workersApi.baseURL,
        },
      )

      this.$patch({
        users,
        usersFilters: filters,
      })

      return this.users
    },

    async getWorkspace(workspaceId: string) {
      const workersApi = useWorkersApi()

      const data: PaAPI.Get['/workspace/{id}'] = await workersApi.fetch(
        `/admin/workspace/${workspaceId}`,
        {
          baseURL: workersApi.baseURL,
        },
      )
      this.workspaces = {
        ...this.workspaces,
        [data.id]: data,
      }

      return this.workspaces[workspaceId]
    },

    async getWorkspaceConnecters(workspaceId: string) {
      const workersApi = useWorkersApi()

      const data = await workersApi.fetch(
        `/admin/workspace/${workspaceId}/connector`,
        {
          baseURL: workersApi.baseURL,
        },
      )
      this.currentConnectors = data || []

      return data
    },

    async update(
      workspaceId: string,
      workspace: Partial<PaAPI.PutBody['/workspace/{id}']>,
    ) {
      const { $toast } = useNuxtApp()
      const workersApi = useWorkersApi()

      try {
        const data = await workersApi.fetch(`/admin/workspace/${workspaceId}`, {
          baseURL: workersApi.baseURL,
          method: 'PUT',
          body: workspace,
          params: {
            include: true,
          },
        })

        $toast.success(`${data.workspace.name} updated.`)

        if (data.errors?.length) {
          setTimeout(() => {
            $toast.warning(`Errors: ${data.errors}`)
          }, 300)
        }

        this.workspaces = {
          ...this.workspaces,
          [data.workspace.id]: data.workspace,
        }

        return this.workspaces
      } catch (err) {
        $toast.error(`Error when updating ${workspace.name}`)
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useAdminWorkspaceManger, import.meta.hot),
  )
